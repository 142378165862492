import * as React from 'react';
import {
  Dialog,
  Button,
  Col,
  Row,
  Switch,
  PrimaryTextSpan,
  PrimaryTextH5,
  BackgroundRequester,
} from 'elements';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { usePrice, useCostEstimation } from 'hooks';
import { CostResourceIds, APP_SERVICES_GROUP } from 'enums';
import { numberToCurrency, remCalc } from 'utils';
import { useFormik } from 'formik';
import { CostInfoList } from 'pages/services/components/Styled';
import { INITIAL_SERVICES_VALUES } from './constants';
import { convertTypeToServiceNameForDialog } from './helpers';
import { ITriggerDialogProps } from './types';
import { parseVmDetailsFoCalculation } from '../Backup/helpers';
import { observer } from 'mobx-react-lite';

const ServiceTriggerDialog = observer(
  (props: React.PropsWithChildren<ITriggerDialogProps>) => {
    const {
      onSave,
      isRequesting,
      entity,
      initialValues,
      vmCount,
      isEditable,
      osType,
      instance,
      ...dialogProps
    } = props;
    const { t } = useTranslation();
    const isVm = entity === 'vm';

    const prices = usePrice([
      CostResourceIds.monitoring,
      CostResourceIds.outsourceManagement,
    ]);

    const { values, setFieldValue, handleSubmit, resetForm, setValues } =
      useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
      });

    const {
      params,
      calculation,
      isRequesting: isCostRequesting,
    } = useCostEstimation({
      values: {
        ...parseVmDetailsFoCalculation(instance as IVmTypes.Vm),
        ...values,
      },
      serviceIds: [APP_SERVICES_GROUP.Monitoring.id],
      instance: instance,
      paralyzeHook: !isVm,
    });

    const showOutsourceManagement =
      !!prices[CostResourceIds.outsourceManagement].daily;

    const chartsCost =
      (values.chartsEnabled || values.servicesEnabled
        ? prices[CostResourceIds.monitoring].monthly
        : 0) * vmCount;

    const outsourceManagementCost = values.outsourceManagementEnabled
      ? prices[CostResourceIds.outsourceManagement].monthly * vmCount
      : 0;

    const vmChartCost = values.chartsEnabled
      ? calculation.costPerResource[CostResourceIds.monitoring]
      : 0;

    const vmOutsourceManagementCost = values.outsourceManagementEnabled
      ? calculation.costPerResource[CostResourceIds.outsourceManagement]
      : 0;

    const cost = isVm
      ? {
          charts: vmChartCost,
          outsourceManagement: vmOutsourceManagementCost,
          total: R.sum([vmChartCost, vmOutsourceManagementCost]),
        }
      : {
          charts: chartsCost,
          outsourceManagement: outsourceManagementCost,
          total: R.sum([chartsCost, outsourceManagementCost]),
        };

    React.useEffect(() => {
      if (!dialogProps.open) {
        resetForm(INITIAL_SERVICES_VALUES as any);
      } else {
        setValues(initialValues);
      }
    }, [dialogProps.open]);

    return (
      <Dialog
        {...dialogProps}
        fullWidth
        maxWidth="md"
        title={t('monitoring.dialog.title')}
        handleSubmit={handleSubmit}
        actions={
          <>
            <Button
              variant="outlined"
              color="default"
              onClick={dialogProps.onClose}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit" disabled={isRequesting || !isEditable}>
              {t('common.save')}
            </Button>
          </>
        }
      >
        <Row justifyContent="space-between" columnSpacing={2}>
          <Col xs={7}>
            <div className="steel mb-30">{t('monitoring.dialog.content')}</div>
            <div>
              <div className="mb-15">
                <Switch
                  label={t('monitoring.dialog.switchers.charts')}
                  checked={values.chartsEnabled}
                  disabled={!isEditable}
                  onCheck={(chartsEnabled) =>
                    setFieldValue('chartsEnabled', chartsEnabled)
                  }
                />
              </div>
              {osType === 'win' && (
                <div className="mb-15">
                  <Switch
                    label={t('monitoring.dialog.switchers.processing')}
                    checked={values.servicesEnabled}
                    disabled={!isEditable}
                    onCheck={(servicesEnabled) =>
                      setFieldValue('servicesEnabled', servicesEnabled)
                    }
                  />
                </div>
              )}
              {showOutsourceManagement && (
                <div className="mb-15">
                  <Switch
                    label={t('monitoring.dialog.switchers.outsourceManagement')}
                    checked={values.outsourceManagementEnabled}
                    disabled={!isEditable}
                    onCheck={(outsourceManagementEnabled) =>
                      setFieldValue(
                        'outsourceManagementEnabled',
                        outsourceManagementEnabled
                      )
                    }
                  />
                </div>
              )}
            </div>
          </Col>
          <Col xs={5}>
            <BackgroundRequester
              isRequesting={isRequesting || isCostRequesting}
            >
              <Row
                direction="column"
                justifyContent="space-between"
                columnSpacing={2}
                style={{ minHeight: remCalc(250) }}
              >
                <Col className="full-width">
                  <PrimaryTextH5 className="mb-25">
                    {t('costsInfo.title')}
                  </PrimaryTextH5>
                  <Row
                    justifyContent="space-between"
                    className="fs-10 steel uppercase mb-15"
                  >
                    <Col xs={7}>
                      <span>{t('costsInfo.head.serviceName')}</span>
                    </Col>
                    <Col xs={2}>
                      <span>{t('costsInfo.head.quantity')}</span>
                    </Col>
                    <Col xs={3} className="text-right">
                      <span>{t('costsInfo.head.price')}</span>
                    </Col>
                  </Row>
                  <h5 className="mb-15">
                    {t(
                      `costsInfo.${convertTypeToServiceNameForDialog(entity)}`
                    )}
                  </h5>
                  <CostInfoList className="custom-list">
                    <ul>
                      <li className="mb-5">
                        <Row>
                          <Col xs={7}>
                            <span>{t('costsInfo.monitoring')}</span>
                          </Col>

                          <Col xs={2} className="steel">
                            <span>{vmCount}</span>
                          </Col>

                          <Col xs={3} className="text-right steel">
                            <span>{numberToCurrency(cost.charts, false)}</span>
                          </Col>
                        </Row>
                      </li>
                      {showOutsourceManagement && (
                        <li className="mb-5">
                          <Row>
                            <Col xs={7}>
                              <span>{t('costsInfo.outsourceManagement')}</span>
                            </Col>

                            <Col xs={2} className="steel" />

                            <Col xs={3} className="text-right steel">
                              <span>
                                {numberToCurrency(
                                  cost.outsourceManagement,
                                  false
                                )}
                              </span>
                            </Col>
                          </Row>
                        </li>
                      )}
                    </ul>
                  </CostInfoList>
                </Col>
                <Col className="full-width">
                  <div className="flex justify-between align-center">
                    <h5 className="fs-17">
                      {isVm
                        ? t(
                            `costsInfo.totalCost.${
                              params.billingSettings?.billingCycleInterval ||
                              'NONE'
                            }`
                          )
                        : t('costsInfo.totalCost.NONE')}
                    </h5>
                    <PrimaryTextSpan className="fs-20 bold">
                      {numberToCurrency(cost.total, false)}
                    </PrimaryTextSpan>
                  </div>
                  <div className="fs-12 steel">{t('costsInfo.chfExclVat')}</div>
                </Col>
              </Row>
            </BackgroundRequester>
          </Col>
        </Row>
      </Dialog>
    );
  }
);

export default ServiceTriggerDialog;
